@use '@angular/material' as mat;
@use 'src/app/theming' as app;

@use '../src/app/components/spinner-container/spinner-container-theme' as spinner;
@use '../node_modules/angular-calendar/scss/angular-calendar.scss' as cal;

@font-face {
  font-family: MontserratRegular;
  src: url(assets/font/Montserrat-Regular.ttf) format("truetype");
}

@font-face {
  font-family: openSans;
  src: url(assets/font/dt-font/OpenSans-Regular.ttf) format("truetype");
}

* {
  font-family: MontserratRegular;
}

// ---- Scrollbar light theme ---- //

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track, ::-webkit-scrollbar-thumb {
  border-radius: 5px;
}

::-webkit-scrollbar-track, ::-webkit-scrollbar-corner {
  background: rgba(241, 241, 241, 1);
}

::-webkit-scrollbar-thumb {
  background: rgba(193, 193, 193, 1);
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(145, 145, 145, 1);
}

// ---- Scrollbar dark theme ---- //

@mixin darkScrollbar {

  ::-webkit-scrollbar-track, ::-webkit-scrollbar-corner {
    background: rgba(66, 66, 66, 1);
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(104, 104, 104, 1);
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgba(142, 142, 142, 1);
  }

}

// ---- Calendar theme ---- //

@mixin calendarTheme {

  $bg-dark-primary: #1f262d;
  $bg-dark-secondary: #394046;
  $bg-active: #2c343a;
  $text-color: #d5d6d7;
  $border-color: rgba(0, 0, 0, 0.6);

  @include cal.cal-theme(
    (
      bg-primary: $bg-dark-primary,
      bg-secondary: $bg-dark-secondary,
      weekend-color: indianred,
      bg-active: $bg-active,
      border-color: $border-color,
      gray: $bg-dark-secondary,
      today-bg: $bg-dark-secondary,
      event-color-primary: $bg-dark-secondary,
    )
  );

}

// ---- Application theme ---- //

@include mat.core();

$font: mat.define-typography-config(
  $font-family: 'MontserratRegular'
);

$light-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette(mat.$deep-purple-palette),
    accent: mat.define-palette(mat.$amber-palette, A200, A100, A400),
    warn: mat.define-palette(mat.$red-palette)
  )
));

$dark-theme-aixp: app.define-dark-theme((
  color: (
    primary: mat.define-palette(mat.$deep-purple-palette, 300, 200, 500),
    accent: mat.define-palette(mat.$amber-palette, A200, A100, A400),
    warn: mat.define-palette(mat.$red-palette)
  )
));

$dark-theme-saratoga: mat.define-dark-theme((
  color: (
    primary: mat.define-palette(mat.$deep-purple-palette, 300, 200, 500),
    accent: mat.define-palette(mat.$amber-palette, A200, A100, A400),
    warn: mat.define-palette(mat.$red-palette)
  )
));

@include mat.all-component-themes($light-theme);
@include mat.all-component-typographies($font);
@include spinner.spinner-container-theme($light-theme);

.darkModeAixp {

  @include mat.all-component-colors($dark-theme-aixp);
  @include spinner.spinner-container-theme($dark-theme-aixp);

  @include calendarTheme();
  @include darkScrollbar();

  mat-sidenav-container {
    background-color: #010012 !important;
  }

}

.darkModeSaratoga {

  @include mat.all-component-colors($dark-theme-saratoga);
  @include spinner.spinner-container-theme($dark-theme-saratoga);

  @include calendarTheme();
  @include darkScrollbar();

}

.dt-links {
  a {
    color: yellow;
  }
}

/*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-mdc-tab-group.mat-tab-fill-height .mat-tab-body-wrapper {
  flex: 1 1 100%;
}

main {
  flex: 1;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

.component-main {
  padding: 40px;
  padding-top: 20px;
}

.truncate-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

th.mat-mdc-header-cell, td.mat-mdc-cell {
  padding-left: 25px !important;
  text-align: left;
  vertical-align: middle;
}

tr.mat-mdc-row {
  height: 60px !important;
}

.my-headers-align .mat-expansion-panel-header-title,
.my-headers-align .mat-expansion-panel-header-description {
    flex-basis: 0;
}

.my-headers-align .mat-expansion-panel-header-description {
    justify-content: space-between;
    align-items: center;
}

.table-container {
  overflow: auto;
  height: fit-content;
  max-height: 100%;
}

.buttonFloating {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  transform: translate(50%, -50%) !important;
}

.mat-sidenav-content {
  min-height: 100% !important;
  display: flex !important;
  flex-direction: column !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of menu that may no longer apply for the MDC version.*/
.mat-menu-highlight {
  background-color: #673ab7 !important;
  color: white !important;
}

.mat-mdc-menu-panel {
  max-width: none !important;
}

.div-inner-main {
  min-width: 30%;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
  //margin-bottom: 50px;
}

.center-item {
  margin: 0 auto;
  text-align: center;
}

.mat-mdc-tooltip {
  font-size: 16px !important;
}

.cssload-container {
  position: absolute;
  top: 20vh;
  left: 50%;
  z-index: 1000;
  transform: translate(-50%, -50%);
}

/*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
mat-radio-button {
  padding-right: 10px !important;
}

.multiline-tooltip {
  white-space: pre;
}

.grid-container {
  display: grid;
  justify-content: center;
  gap: 40px;
  width: 100%;
}

.event-class .cal-event-title {
  color: black;
}

.calendar-lesson-title {
  font-weight: bold;
  font-size: 14px;
}

.calendar-lesson-description {
  white-space: normal;
}

.survey-container {
  background-color: white;
  color: black;
  padding: 20px;
}

.kpi-light-card-theme {
  @include mat.card-color($light-theme);
  @include mat.button-color($light-theme);

  mat-card-title, mat-card-content {
    font-weight: bold !important;
    color: black;
  }

  mat-card-title {
    font-size: 16px;
  }

  mat-card-content {
    font-size: 14px;
  }
}

.mdc-text-field__input::-webkit-calendar-picker-indicator {
  display: block !important;
}

button.mat-mdc-fab:not(button.mat-mdc-extended-fab) {
  height: 48px;
  width: 48px;
}
